import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './InitializeApp'));

const root = ReactDOM.createRoot(document.getElementById('root'));



const Main = async () => {
  root.render(
      <Provider store={configureStore()}>
        <Suspense fallback={<div className="loading" />}>
          <App />
        </Suspense>
      </Provider>
  );
  
};

Main();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
